<template>
  <div class="card pt-3 p-0 mt-0 bank-customers">
    <div class="card-header cursor-pointer px-10">
      <div class="card-title d-flex justify-content-between w-100 m-0">
        <h3 class="fw-bolder m-0">{{ $t("Bank's Customers") }}</h3>
        <div class="customers-count">
          <b>302</b> Customers
        </div>
      </div>
    </div>

    <div class="mx-6">
      <el-table
          :data="bankCustomers"
          :show-header="false"
          style="width: 100%">
        <el-table-column prop="icon_path" class-name="bank-customers__icon_path" width="90">
          <template #default="scope">
            <img :src="scope.row.icon_path" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="name" class-name="bank-customers__name">
          <template #default="scope">
            <h1>{{ scope.row.name }}</h1>
            <p>{{ scope.row.title }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="id" class-name="bank-customers__following" width="180" align="center">
          <template #default>
            <button type="button" class="btn btn-success">Following</button>
          </template>
        </el-table-column>
        <el-table-column property="job_title" class-name="bank-customers__job_title" align="center" />
        <el-table-column property="country" class-name="bank-customers__country" align="center" />
        <el-table-column prop="id" class-name="bank-customers__see-profile" align="right">
          <template #default>
            <a href="">See Profile</a>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <a href="#" class="btn w-100 btn-light-primary fw-bolder menage-btn position-static">
      View All
    </a>
  </div>
</template>

<script>
export default {
  name: "BankCustomers",
  props: {
    bankCustomers: Array
  }
}
</script>

<style lang="scss">
.bank-customers {
  .el-table::before {
    background-color: transparent;
  }
  .el-table__row:last-child {
    td.el-table__cell {
      border-bottom: none !important;
    }
  }
  &__icon_path {
    border-bottom: none !important;
    img {
      width: 54px;
      height: 54px;
      object-fit: cover;
      border-radius: 50%;
    }
    .cell {
      padding-left: 20px;
    }
  }
  &__name {
    h1 {
      font-weight: 700;
      font-size: 16px;
      line-height: 158.02%;
      margin: 0;
      color: #2B2B2B;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin: 0;
      color: #A7A7A7;
    }
  }
  &__following {
    .btn {
      background: #6FCF8A;
      padding: 6px 15px !important;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      border-radius: 8px;
    }
  }
  &__job_title,
  &__country {
    .cell {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #8B8B8B;
    }
  }
  &__see-profile {
    .cell {
      padding-right: 42px;
      a {
        font-size: 14px;
        line-height: 17px;
        color: #435BF4;
      }
    }
  }
  .customers-count {
    font-size: 14px;
    line-height: 13px;
    background: #F6F6F6;
    border-radius: 3px;
    padding: 5px 9px;
    b {
      font-weight: 700;
    }
  }
}
</style>
