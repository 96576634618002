<template>
  <div class="row g-5 g-xxl-8 mx-0">
    <div class="card mb-5 mb-xl-10 py-2" id="kt_profile_details_view">
      <div class="card-header cursor-pointer px-0">
        <div class="card-title w-100 d-flex justify-content-between m-0">
          <h3 class="fw-bolder m-0">{{ $t("Profile Details") }}</h3>

          <router-link to="/bank/settings/user-settings/settings">
            <img src="/media/misc/edit-icon.svg" alt="" class="me-2">
            {{ $t('Edit Profile') }}
          </router-link>
        </div>
      </div>
      <BankProfileDetails
        :userDetails="userDetails"
        :profileDetails="user"
        :bankData="bank"
      />
    </div>

<!--    <div class="card pt-3 mb-xl-10 p-0 mt-0">-->
<!--      <div class="card-header cursor-pointer px-10">-->
<!--        <div class="card-title m-0">-->
<!--          <h3 class="fw-bolder m-0">{{ $t("Your Activity") }}</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="coming-soon-section">-->
<!--        {{ $t('Coming Soon') }}-->
<!--      </div>-->
<!--      <div class="d-flex align-items-center justify-content-between px-10 mt-7">-->
<!--        <p class="fs-4 fw-bold text-gray-600">“Questions replied monthly” by Floyd Miles</p>-->
<!--        <p class="fs-4 fw-bold">1021 questions replied</p>-->
<!--      </div>-->
<!--      <BarChart />-->
<!--    </div>-->

<!--    <BankCustomers :bankCustomers="bankCustomers" />-->

  </div>
</template>

<script>
import store from "@/store";
import BankProfileDetails from "@/buying-teams/pages/bank/settings/user/BankProfileDetails";
import BankCustomers from "@/buying-teams/shared-components/settings/BankCustomers";
import { BankUserSettingsContainer } from "@/store/models/bank/BankUserSettingsContainer";
export default {
  name: "Overview",
  components: {
    BankCustomers,
    BankProfileDetails
  },
  props: {
    userDetails: BankUserSettingsContainer
  },
  computed: {
    user() {
      return store.getters.currentUser;
    },
    bank() {
      return store.getters.bank;
    }
  }
}
</script>

<style scoped>

</style>
