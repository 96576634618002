<template>
    <div class="card-body p-9 px-0 profile-details" v-if="profileDetails">
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Full Name") }}</label>
            <div class="col-lg-8">
                <span
                    class="profile-details__text">{{ profileDetails.first_name }} {{ profileDetails.last_name }}</span>
            </div>
        </div>
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Registered Bank") }}</label>
            <div class="col-lg-8 fv-row">
                <span class="profile-details__text">{{ bankData.name }}</span>
            </div>
        </div>
        <div class="row mb-8" v-if="userDetails">
            <label class="col-lg-4 profile-details__label">{{ $t("Followed Countries") }}</label>
            <div class="col-lg-8 d-flex align-items-center flex-wrap profile-details__text-wrapper">
                <span
                    class="profile-details__text"
                    v-if="userDetails.followed_countries.length"
                    v-for="(item, index) in userDetails.followed_countries"
                    :key="index"
                >{{ item }}{{ index !== userDetails.followed_countries.length - 1 ? ",&nbsp;" : "" }}</span>
                <span
                    v-else
                    class="profile-details__text"
                >
                    {{ $t("No Data") }}
                </span>
            </div>
        </div>
        <div class="row">
            <label class="col-lg-4 profile-details__label">{{ $t("Followed Product Areas") }}</label>
            <div class="col-lg-8 d-flex align-items-center flex-wrap profile-details__text-wrapper">
                <span
                    class="profile-details__text"
                    v-if="userDetails.followed_product_areas.length"
                    v-for="(item, index) in userDetails.followed_product_areas"
                    :key="index"
                >
                  {{ item }}{{ index !== userDetails.followed_product_areas.length - 1 ? ",&nbsp;" : "" }}
                </span>

                <span v-else class="profile-details__text">
                  {{ $t("No Data") }}
                </span>
            </div>
        </div>
        <!--    <div class="row">-->
        <!--      <label class="col-lg-4 profile-details__label">{{ $t('Preferred Language') }}</label>-->
        <!--      <div class="col-lg-8">-->
        <!--        <div class="d-flex align-items-center justify-content-start">-->
        <!--          <img :src="profileDetailsLanguage.icon_path" class="me-2" width="20" height="20" alt="">-->
        <!--          <span class="profile-details__text mb-0">{{ profileDetailsLanguage.title }}</span>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
    </div>
</template>
<script>
import { SUPPORTED_LANGUAGES } from "@/core/config/constants";

export default {
    name: "BankProfileDetails",

    props: ["userDetails", "profileDetails", "bankData"],

    data() {
        return {
            supportedLanguages: SUPPORTED_LANGUAGES
        };
    },

    computed: {
        profileDetailsLanguage() {
            return {
                icon_path: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].icon_path : "/media/buying/icons/languages/globe-earth.svg",
                title: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].title : this.$t("No selected")
            };
        }
    }
};
</script>
<style lang="scss" scoped>
.profile-details {
    &__label {
        color: #8B8B8B;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }

    &__text {
        color: #040404;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }

    &__text-wrapper {
        margin-bottom: -10px;
    }
}
</style>
